<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>
    <!--    <div-->
    <!--      v-else-->
    <!--      style="display: flex; flex-direction: row; flex-wrap:wrap; justify-content: space-around; align-content: space-between; margin-top: 50px "-->
    <!--    >-->
    <!--      <v-card outlined height="400" width="700">-->
    <!--        <div style="display: flex; flex-direction: column">-->
    <!--          <div class="ml-3"><PageTitle text="Order Information" /></div>-->
    <!--          <div>-->
    <!--            <v-simple-table>-->
    <!--              <template v-slot:default>-->
    <!--                <thead>-->
    <!--                  <tr>-->
    <!--                    <th-->
    <!--                      class="text-left"-->
    <!--                      style="font-weight: bold; font-size: 17px"-->
    <!--                    >-->
    <!--                      Detail-->
    <!--                    </th>-->
    <!--                    <th-->
    <!--                      class="text-left"-->
    <!--                      style="font-weight: bold; font-size: 17px"-->
    <!--                    >-->
    <!--                      Information-->
    <!--                    </th>-->
    <!--                  </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                  <tr>-->
    <!--                    <td class="text-left">Date</td>-->
    <!--                    <td>{{ details.createdAt | format }}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td>Cheque Number</td>-->
    <!--                    <td>{{ cheque }}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td>Branch Name</td>-->
    <!--                    <td>{{ branchName }}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td>Product Name</td>-->
    <!--                    <td>{{ product }}</td>-->
    <!--                  </tr>-->
    <!--                </tbody>-->
    <!--              </template>-->
    <!--            </v-simple-table>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-card>-->
    <!--      <v-card outlined height="400" width="400" class="blue-grey&#45;&#45;text">-->

    <!--        <div-->
    <!--          style="display: flex; flex-direction: column; justify-content: space-around; align-content: space-between; align-items: center"-->
    <!--        >-->
    <!--          <v-progress-linear-->
    <!--                  v-show="progress"-->
    <!--                  class="mx-6"-->
    <!--                  indeterminate-->
    <!--                  color="cyan"-->
    <!--          ></v-progress-linear>-->
    <!--          <div class="mb-4 mt-4">-->
    <!--            <h3>Order Summary</h3>-->
    <!--          </div>-->

    <!--          <div-->
    <!--            style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"-->
    <!--          >-->
    <!--            <div style="width: 200px"><h5>Order Amount:</h5></div>-->
    <!--            <div style="width: 100px"><h5>GHS 300.00</h5></div>-->
    <!--          </div>-->
    <!--          <div-->
    <!--            style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"-->
    <!--          >-->
    <!--            <div style="width: 200px"><h5>Quantity:</h5></div>-->
    <!--            <div style="width: 100px">-->
    <!--              <h5>{{ details.quantity }}</h5>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div-->
    <!--            style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"-->
    <!--          >-->
    <!--            <div style="width: 200px"><h5>Total:</h5></div>-->
    <!--            <div style="width: 100px"><h5>GHS 300.00</h5></div>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div-->
    <!--          style="margin-top: 180px"-->
    <!--          class="d-flex flex-wrap justify-space-between mb-6"-->
    <!--        >-->
    <!--          <div></div>-->
    <!--          <div>-->
    <!--            <v-btn v-if="marked === false" class="mb-2" small depressed color="success" @click="markAsCompleted()">-->
    <!--              Mark As Completed-->
    <!--            </v-btn>-->
    <!--            <v-btn v-else small depressed color="warning" class="mb-2" >-->
    <!--              Completed-->
    <!--            </v-btn>-->
    <!--            <v-btn small depressed color="error" class="mb-2 ml-2 mr-1">-->
    <!--              Cancel-->
    <!--            </v-btn>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-card>-->
    <!--    </div>-->

    <v-container fluid grid-list-md v-else>
      <div class="ml-3 mb-n2">
        <v-breadcrumbs
          :items="items"
          :to="items.link"
          divider=" - "
        ></v-breadcrumbs>
      </div>
      <v-layout row wrap>
        <v-flex xs12 sm6 class="ml-7">
          <v-card outlined height="400" width="700">
            <div style="display: flex; flex-direction: column">
              <div class="ml-3"><PageTitle text="Order Information" /></div>
              <div>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                          style="font-weight: bold; font-size: 17px"
                        >
                          Detail
                        </th>
                        <th
                          class="text-left"
                          style="font-weight: bold; font-size: 17px"
                        >
                          Information
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">Date</td>
                        <td>{{ details.createdAt | format }}</td>
                      </tr>
                      <tr>
                        <td>Cheque Number</td>
                        <td>{{ cheque }}</td>
                      </tr>
                      <tr>
                        <td>Branch Name</td>
                        <td>{{ branchName }}</td>
                      </tr>
                      <tr>
                        <td>Product Name</td>
                        <td>{{ product }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-card> </v-flex
        ><v-flex xs12 sm5 class="ml-7"
          ><v-card outlined height="400" width="400" class="blue-grey--text">
            <div
              style="display: flex; flex-direction: column; justify-content: space-around; align-content: space-between; align-items: center"
            >
              <v-progress-linear
                v-show="progress"
                class="mx-6"
                indeterminate
                color="cyan"
              ></v-progress-linear>
              <div class="mb-4 mt-4">
                <h3>Order Summary</h3>
              </div>

              <div
                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
              >
                <div style="width: 200px"><h5>Order Amount:</h5></div>
                <div style="width: 100px"><h5>GHS 300.00</h5></div>
              </div>
              <div
                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
              >
                <div style="width: 200px"><h5>Quantity:</h5></div>
                <div style="width: 100px">
                  <h5>{{ details.quantity }}</h5>
                </div>
              </div>
              <div
                style="display: flex; flex-direction: row; justify-content: space-around; padding: 10px 10px"
              >
                <div style="width: 200px"><h5>Total:</h5></div>
                <div style="width: 100px"><h5>GHS 300.00</h5></div>
              </div>
            </div>

            <div
              style="margin-top: 180px"
              class="d-flex flex-wrap justify-space-between mb-6"
            >
              <div></div>
              <div>
                <v-btn
                  v-if="marked === false"
                  class="mb-2"
                  small
                  depressed
                  color="success"
                  @click="markAsCompleted()"
                >
                  Mark As Completed
                </v-btn>
                <v-btn v-else small depressed color="warning" class="mb-2">
                  Completed
                </v-btn>
                <v-btn small depressed color="error" class="mb-2 ml-2 mr-1">
                  Cancel
                </v-btn>
              </div>
            </div>
          </v-card></v-flex
        ></v-layout
      ></v-container
    >
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../../components/PageTitle";
import * as axios from "axios";
import Spinner from "vue-simple-spinner";
import moment from "moment";
export default {
  name: "OrderDetails",
  components: { PageTitle, spinner: Spinner },
  data: () => ({
    details: {},
    ready: true,
    ok: "",
    branchName: "",
    product: "",
    status: "",
    showSnackBar: false,
    message: "",
    cheque: "",
    amount: "",
    progress: false,
    marked: false,
    items: [
      {
        text: "Orders",
        disabled: false,
        name: "master.orders"
      },
      {
        text: "Order",
        disabled: false
      }
    ]
  }),

  filters: {
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    }
  },

  methods: {
    getOrder() {
      let id = this.$route.params.id;
      console.log(id);
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/${id}`
      })
        .then(response => {
          console.log(response);
          this.ready = false;
          if (response.status === 200) {
            this.details = response.data;
            this.marked = this.details.isOrderCompleted;
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    },

    markAsCompleted() {
      let id = this.$route.params.id;
      console.log(id);
      this.progress = true;
      axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/${id}/mark/as/completed`,
        data: {
          isOrderCompleted: true
        }
      })
        .then(response => {
          console.log(response);
          this.ready = false;
          this.progress = false;
          this.showSnackBar = true;
          this.status = "success";
          this.message = "Order completed successfully.";
          this.getOrder();
          if (response.status === 200) {
            this.details = response.data;
          }
        })
        .catch(err => {
          this.progress = true;
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },
  created() {
    this.getOrder();
    this.branchName = this.$route.params.branchName;
    this.cheque = this.$route.params.cheque;
    this.amount = this.$route.params.amount;
    this.product = this.$route.params.product;
  }
};
</script>

<style scoped></style>
